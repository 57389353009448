import React from 'react'
import { Link } from 'react-router-dom'



export default function Services() {
  return (
    <div className="services">
    <a href='/1' ><img src="LEKKEBANDPECHBIJSTAND.png" alt="" /></a>
    <a href='/2'><img src="ZOMERWINTERWISSEL.png" alt="" /></a>
    <a href='/3'><img src="ONLINEBANDENKOPEN.png" alt="" /></a>
    <a href='/4'><img src="GEENBRANDSTOF.png" alt="" /></a>
    <a href='/5'><img src="BATTERIJLEEG.png" alt="" /></a>
    <a href='/6'><img src="SLOTBOUTVERWIJDEREN.png" alt="" /></a>
    <a href='/7'><img src="TAKELEN.png" alt="" /></a>
    <a href='/8'><img src="B2BBANDENWISSEL.png" alt="" /></a>


  </div>

  )
}
