import React, { useEffect, useState } from 'react'
import Panel from './Panel'

export default function Main() {


  return (


    <div className='Main' >
    <img className='BG'  src="bg.jpeg" alt="" />
   
   
    <Panel></Panel>

    </div>

  )
}
